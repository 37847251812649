"use client";
import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { GetUserProfile } from "@/utils/user";
import { SignOut } from "@/utils/signFunctions";
import { UserProfile } from "@/types/database_types";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { usePathname } from "next/navigation";
const supabase = createClientComponentClient();

type PaywallProps = {
  changePricePlan?: boolean;
  setChangePricePlan?: (value: boolean) => void;
};

const Paywall: React.FC<PaywallProps> = ({
  changePricePlan,
  setChangePricePlan,
}) => {
  const pathname = usePathname();
  const router = useRouter();
  const [user, setUser] = useState<UserProfile | null>();
  const [togglePricePlan, setTogglePricePlan] = useState<boolean>(false);
  /* MODE TEST */
  //const priceIdBasique = 'price_1Ng5hfLNPg8Ijl6mhh5lGEYO';
  //const priceIdPremium = 'price_1NgQipLNPg8Ijl6mZ4jDjIG3';

  /* MODE PRODUCTION */
  const priceIdBasique = "price_1NrcwqLNPg8Ijl6mVCEVJ43J";
  const priceIdPremium = "price_1Nrcx0LNPg8Ijl6m5jwyvkpn";

  const handleUserPayement = async () => {
    let userInfos = await GetUserProfile();
    if (Array.isArray(userInfos)) {
      setUser(userInfos[0] as UserProfile);
      if (
        ((userInfos[0] && !userInfos[0].plan) || changePricePlan) &&
        pathname !==
          ("/" ||
            "/usage" ||
            "/confidencials-policies" ||
            "/legals" ||
            "/faq" ||
            "/contact" ||
            "/blog")
      ) {
        setTogglePricePlan(true);
      }
      if (
        userInfos[0] &&
        userInfos[0].plan &&
        userInfos[0].subscription_status != "canceled"
      ) {
        setTogglePricePlan(false);
      }
    } else {
      console.error("Paywall: User subscription problem.");
    }
  };

  const handleSignOut = async () => {
    setTogglePricePlan(false);
    await SignOut();
    router.push("/");
  };

  useEffect(() => {
    handleUserPayement();
  }, []);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      console.log("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      console.log(
        "Order canceled -- continue to shop around and checkout when you’re ready."
      );
    }
  }, []);

  return (
    <>
      {togglePricePlan || changePricePlan ? (
        <>
          <div
            className="z-[999999] absolute h-auto sm:h-full w-full sm:fixed top-0 left-0 flex justify-center md:items-center backdrop-blur-md bg-black bg-opacity-10"
            // onClick={() => {
            //     if (user && user.plan) {
            //         setTogglePricePlan(false); setChangePricePlan(false)
            //     }
            // }}
          >
            <style global jsx>{`
              body {
                height: 100% !important;
                min-height: 100vh;
              }
            `}</style>
            <div className="p-8  bg-black2/80 xl:w-2/3 rounded-xl flex flex-col gap-8 sm:h-auto lg:px-8 relative w-full h-full">
              {user && user.plan ? (
                <button
                  className="absolute top-1 right-2"
                  onClick={() => {
                    setTogglePricePlan(false);
                    if (setChangePricePlan) {
                      setChangePricePlan(false);
                    }
                  }}
                >
                  <svg
                    className="hover:fill-slate-300 transition"
                    width={46}
                    height={46}
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m13.59 12.002 4.454-4.453a1.126 1.126 0 0 0-1.59-1.594L12 10.408 7.547 5.955A1.127 1.127 0 1 0 5.953 7.55l4.453 4.453-4.453 4.453a1.127 1.127 0 1 0 1.594 1.594L12 13.596l4.453 4.453a1.127 1.127 0 1 0 1.594-1.594l-4.456-4.453Z" />
                  </svg>
                </button>
              ) : null}

              <div className="flex md:flex-row flex-col w-full justify-between gap-8">
                <div className="flex flex-col gap-2">
                  <h3 className="text-white2 text-5xl font-bold">
                    Choisissez votre plan
                  </h3>
                  <p className="text-slate-300">
                    Changer votre plan à tout moment pour l’adapter à vos
                    besoins.
                  </p>
                </div>
                <div className="mr-8">
                  <button
                    className="border border-slate-400  hover:border-slate-100 h-[40px] flex cursor-pointer items-center truncate rounded-[8px] px-3 py-2 text-[0.85rem] text-white-600 outline-none transition duration-300 ease-linear hover:backdrop-blur-md hover:bg-white hover:bg-opacity-10 hover:text-inherit hover:outline-none focus:bg-gray2 focus:text-inherit focus:outline-none active:bg-gray2 active:text-inherit active:outline-none data-[te-sidenav-state-active]:text-inherit data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                    data-te-sidenav-link-ref
                    onClick={handleSignOut}
                  >
                    <span className="mr-4 [&>svg]:h-5 [&>svg]:w-5 [&>svg]:text-white dark:[&>svg]:text-gray-300">
                      <svg
                        width={46}
                        height={46}
                        fill="#EF9393"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.65 1.65H12.8v1.5H3.15v17.7h9.65v1.5H1.65V1.65Zm15.68 5.02 5.31 5.31-5.289 5.729-1.102-1.018 3.638-3.941H6.4v-1.5h13.39l-3.52-3.52 1.06-1.06Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span>Déconnexion</span>
                  </button>
                </div>
              </div>
              <div className="h-full w-full justify-center xl:items-center flex-col flex md:flex-row">
                <div className="h-full max-w-[1350px] justify-start md:justify-center items-center md:items-center flex-col flex md:flex-row gap-8">
                  <div className="relative min-w-[350px] max-w-[450px]  px-8 py-8 backdrop-blur-md bg-white bg-opacity-10 shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] rounded-3xl flex flex-col border border-white/10 w-full md:w-1/3">
                    {user && user.plan === "basique" ? (
                      <div className="absolute h-full w-full bg-black2/60 flex justify-center items-center left-0 top-0 rounded-3xl">
                        <p className="font-bold text-3xl text-white2 ">
                          Votre plan actuel
                        </p>
                      </div>
                    ) : null}

                    <div className="w-full flex justify-start">
                      <p className="text-white2 px-8 py-1 rounded-full bg-white/10 border-white border">
                        Basique
                      </p>
                    </div>
                    <div className="justify-start flex flex-col w-full py-8 border-b border-slate-200 gap-8">
                      <div className="flex items-end gap-4">
                        <p className="text-white text-5xl font-semibold">
                          19,90 €
                        </p>
                        <p className="text-slate-200">/ mois</p>
                      </div>
                      <p className="text-slate-200 text-xl">
                        La meilleure option pour démarrez votre aventure avec
                        l&apos;IA
                      </p>
                    </div>
                    <ul className="pt-8 flex flex-col gap-4">
                      <li className="flex gap-4">
                        <svg
                          width={26}
                          height={26}
                          fill="none"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                          <path d="m9 12 2 2 4-4" />
                        </svg>
                        <p>Tous les outils IA</p>
                      </li>
                      <li className="flex gap-4">
                        <svg
                          width={26}
                          height={26}
                          fill="none"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                          <path d="m9 12 2 2 4-4" />
                        </svg>
                        <p>100 000 crédits</p>
                      </li>
                      <li className="flex gap-4">
                        <svg
                          width={26}
                          height={26}
                          fill="none"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                          <path d="m9 12 2 2 4-4" />
                        </svg>
                        <p>Support client standard</p>
                      </li>
                    </ul>
                    <form
                      action={
                        changePricePlan
                          ? "/api/update-subscription"
                          : "/api/checkout_sessions"
                      }
                      method="POST"
                      className="w-full flex justify-center items-center pt-8"
                    >
                      {changePricePlan && (
                        <input
                          type="hidden"
                          name="user"
                          value={JSON.stringify(user)}
                        />
                      )}
                      <input
                        type="hidden"
                        name="priceId"
                        value={priceIdBasique}
                      />
                      {user && !changePricePlan && (
                        <input type="hidden" name="user" value={user.id} />
                      )}

                      <button
                        type="submit"
                        role="link"
                        className="bg-white/10 py-2 px-8 rounded-md border border-white font-bold hover:bg-white/20 hover:shadow-xl transition"
                      >
                        Essayer Gratuitement
                      </button>
                    </form>
                  </div>

                  <div className="relative min-w-[350px] max-w-[450px] px-8 py-8 backdrop-blur-md bg-white bg-opacity-10 shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] rounded-3xl flex flex-col border border-[#FFCF54] w-full md:w-1/3">
                    {user && user.plan === "premium" ? (
                      <div className="absolute h-full w-full z-10 bg-black2/60 flex justify-center items-center left-0 top-0 rounded-3xl">
                        <p className="font-bold text-3xl text-white2 ">
                          Votre plan actuel
                        </p>
                      </div>
                    ) : null}

                    <div className="absolute -top-6 px-4 flex justify-center items-center bg-[#FFCF54] text-white font-medium rounded-t-xl">
                      <p>Le + populaire</p>
                    </div>
                    <div className="w-full flex justify-start">
                      <p className="text-white2 px-8 py-1 rounded-full bg-[#ffcf5433] border-[#FFCF54] border">
                        Premium
                      </p>
                    </div>
                    <div className="justify-start flex flex-col w-full py-8 border-b border-slate-200 gap-8">
                      <div className="flex items-end gap-4">
                        <p className="text-white text-5xl font-semibold">
                          29,90 €
                        </p>
                        <p className="text-slate-200">/ mois</p>
                      </div>
                      <p className="text-slate-200 text-xl">
                        L&apos;option la plus populaire pour débloquez votre
                        potentiel avec l&apos;IA.
                      </p>
                    </div>
                    <ul className="pt-8 flex flex-col gap-4">
                      <li className="flex gap-4">
                        <svg
                          width={26}
                          height={26}
                          fill="none"
                          stroke="#FFCF54"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                          <path d="m9 12 2 2 4-4" />
                        </svg>
                        <p>Nombre de mots IA illimité</p>
                      </li>
                      <li className="flex gap-4">
                        <svg
                          width={26}
                          height={26}
                          fill="none"
                          stroke="#FFCF54"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                          <path d="m9 12 2 2 4-4" />
                        </svg>
                        <p>Tous les outils IA</p>
                      </li>
                      <li className="flex gap-4">
                        <svg
                          width={26}
                          height={26}
                          fill="none"
                          stroke="#FFCF54"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                          <path d="m9 12 2 2 4-4" />
                        </svg>
                        <p>200 000 crédits</p>
                      </li>
                      <li className="flex gap-4">
                        <svg
                          width={26}
                          height={26}
                          fill="none"
                          stroke="#FFCF54"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                          <path d="m9 12 2 2 4-4" />
                        </svg>
                        <p>Support client prioritaire</p>
                      </li>
                    </ul>
                    <form
                      action={
                        changePricePlan
                          ? "/api/update-subscription"
                          : "/api/checkout_sessions"
                      }
                      method="POST"
                      className="w-full flex justify-center items-center pt-8"
                    >
                      {changePricePlan && (
                        <input
                          type="hidden"
                          name="user"
                          value={JSON.stringify(user)}
                        />
                      )}
                      <input
                        type="hidden"
                        name="priceId"
                        value={priceIdPremium}
                      />
                      {user && !changePricePlan && (
                        <input type="hidden" name="user" value={user.id} />
                      )}

                      <button
                        type="submit"
                        role="link"
                        className="bg-white/10 py-2 px-8 rounded-md border border-[#FFCF54] font-bold hover:bg-white/20 hover:shadow-xl transition"
                      >
                        Essayer Gratuitement
                      </button>
                    </form>
                  </div>
                  {/* 
                                            <div className='min-w-[350px] max-w-[450px] px-8 py-8 backdrop-blur-md bg-white bg-opacity-10 shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] rounded-3xl flex flex-col border border-white/10 w-full md:w-1/3'>
                                                <div className='w-full flex justify-start'>
                                                    <p className='text-white2 px-8 py-1 rounded-full bg-[#43ADD0] border-[#43ADD0] border'>Entreprise</p>
                                                </div>
                                                <div className='justify-start flex flex-col w-full py-8 border-b border-slate-200 gap-4'>
                                                    <div className='flex items-end gap-4'>
                                                        <p className='text-white text-5xl font-bold'>
                                                            Custom
                                                        </p>
                                                        <p className='text-slate-200'>
                                                            / mois
                                                        </p>
                                                    </div>
                                                    <p className='text-slate-200 text-xl'>
                                                        Optimisez votre entreprise  avec un plan sur mesure conçu pour répondre à vos besoins spécifiques.
                                                    </p>
                                                </div>
                                                <ul className='pt-8 flex flex-col gap-4'>
                                                    <li className='flex gap-4'>
                                                        <svg width={26} height={26} fill="none" stroke="#43ADD0" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                                                            <path d="m9 12 2 2 4-4" />
                                                        </svg>
                                                        <p>Tous les outils IA</p>
                                                    </li>
                                                    <li className='flex gap-4'>
                                                        <svg width={26} height={26} fill="none" stroke="#43ADD0" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                                                            <path d="m9 12 2 2 4-4" />
                                                        </svg>
                                                        <p>Crédits à la demande</p>
                                                    </li>
                                                    <li className='flex gap-4'>
                                                        <svg width={26} height={26} fill="none" stroke="#43ADD0" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" />
                                                            <path d="m9 12 2 2 4-4" />
                                                        </svg>
                                                        <p>Support client prioritaire</p>
                                                    </li>
                                                </ul>
                                                <div className='w-full flex justify-center items-center pt-8'>
                                                    <button className='bg-white/10 py-2 px-8 rounded-md border border-[#43ADD0] font-bold'>
                                                        Essayer Gratuitement
                                                    </button>
                                                </div>
                                            </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Paywall;
